import { render, staticRenderFns } from "./HerbalDetail.vue?vue&type=template&id=7eae540f&scoped=true&"
import script from "./HerbalDetail.vue?vue&type=script&lang=js&"
export * from "./HerbalDetail.vue?vue&type=script&lang=js&"
import style0 from "./HerbalDetail.vue?vue&type=style&index=0&id=7eae540f&prod&scoped=true&lang=css&"
import style1 from "./HerbalDetail.vue?vue&type=style&index=1&id=7eae540f&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eae540f",
  null
  
)

export default component.exports